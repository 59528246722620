/* Global Styles */
:root {
  --primary-color: #2c3e50;
  --secondary-color: #3498db;
  --accent-color: #e74c3c;
  --light-color: #ecf0f1;
  --dark-color: #2c3e50;
  --text-color: #333;
  --border-radius: 8px;
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;
}

body {
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--light-color);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin-bottom: 1rem;
}

a {
  color: var(--secondary-color);
  text-decoration: none;
  transition: var(--transition);
}

a:hover {
  color: var(--primary-color);
}

.section {
  padding: 5rem 0;
}

.section-title {
  margin-bottom: 3rem;
  text-align: center;
}

.section-title h2 {
  position: relative;
  display: inline-block;
  margin-bottom: 1.5rem;
  font-size: 2.5rem;
}

.section-title h2:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background-color: var(--secondary-color);
}

.btn-primary {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  padding: 0.75rem 1.5rem;
  border-radius: var(--border-radius);
  font-weight: 600;
  transition: var(--transition);
}

.btn-primary:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.btn-outline-light {
  color: white;
  border-color: white;
  padding: 0.75rem 1.5rem;
  border-radius: var(--border-radius);
  font-weight: 600;
  transition: var(--transition);
}

.btn-outline-light:hover {
  background-color: white;
  color: var(--primary-color);
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Navbar Styles */
.navbar {
  padding: 1rem 0;
  transition: var(--transition);
}

.navbar.scrolled {
  padding: 0.5rem 0;
  background-color: var(--primary-color) !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: 700;
}

.nav-link {
  font-weight: 600;
  margin-left: 0.5rem;
  padding: 0.5rem 1rem !important;
  border-radius: var(--border-radius);
  transition: var(--transition);
}

.nav-link.active, .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Hero Section */
.hero {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  padding: 0 1rem;
  overflow: hidden;
  background: linear-gradient(rgba(44, 62, 80, 0.8), rgba(44, 62, 80, 0.8)), linear-gradient(to right, #2c3e50, #3498db);
}

.hero-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.hero-content {
  max-width: 800px;
}

.hero-content h1 {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  animation: fadeInDown 1s ease-out;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  opacity: 0.9;
  animation: fadeInUp 1s ease-out;
}

.hero-content div {
  animation: fadeIn 1.5s ease-out;
}

/* About Section */
.about-content h3 {
  color: var(--secondary-color);
  margin-bottom: 1.5rem;
}

.about-image {
  overflow: hidden;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

/* Services Section */
.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.services-card {
  background: white;
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  transition: var(--transition);
}

.services-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.services-card .icon {
  font-size: 3rem;
  color: var(--secondary-color);
  margin-bottom: 1.5rem;
}

.services-card h3 {
  margin-bottom: 1rem;
}

/* Portfolio Section */
.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 2rem;
}

.portfolio-item {
  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow);
}

.portfolio-item img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  transition: var(--transition);
}

.portfolio-item:hover img {
  transform: scale(1.1);
}

.portfolio-item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(44, 62, 80, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: white;
  text-align: center;
  opacity: 0;
  transition: var(--transition);
}

.portfolio-item:hover .overlay {
  opacity: 1;
}

.portfolio-item .overlay h3 {
  margin-bottom: 0.5rem;
}

.portfolio-item .overlay p {
  margin-bottom: 1.5rem;
}

/* Testimonials Section */
.testimonials-item {
  background: white;
  padding: 3rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  text-align: center;
  margin: 0 auto;
  max-width: 800px;
}

.testimonials-item p {
  font-style: italic;
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

.testimonials-item .client {
  font-weight: 700;
  color: var(--secondary-color);
}

/* CTA Section */
.cta-section {
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
  color: white;
  padding: 5rem 0;
}

.cta-section h2 {
  margin-bottom: 1.5rem;
  font-size: 2.5rem;
}

/* Footer */
.footer {
  background-color: var(--dark-color);
  color: white;
  padding: 5rem 0 0;
}

.footer-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.footer-logo {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.social-icons a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  transition: var(--transition);
}

.social-icons a:hover {
  background-color: var(--secondary-color);
  transform: translateY(-3px);
}

.footer-links h3 {
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  position: relative;
  padding-bottom: 0.5rem;
}

.footer-links h3:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 2px;
  background-color: var(--secondary-color);
}

.footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links ul li {
  margin-bottom: 0.75rem;
}

.footer-links ul li a {
  color: rgba(255, 255, 255, 0.8);
  transition: var(--transition);
}

.footer-links ul li a:hover {
  color: var(--secondary-color);
  padding-left: 5px;
}

.footer-contact h3 {
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  position: relative;
  padding-bottom: 0.5rem;
}

.footer-contact h3:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 2px;
  background-color: var(--secondary-color);
}

.info-item {
  display: flex;
  margin-bottom: 1rem;
}

.info-item .icon {
  margin-right: 1rem;
  color: var(--secondary-color);
}

.info-item p {
  margin: 0;
}

.info-item a {
  color: rgba(255, 255, 255, 0.8);
  transition: var(--transition);
}

.info-item a:hover {
  color: var(--secondary-color);
}

.footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1.5rem 0;
  text-align: center;
}

.footer-bottom p {
  margin: 0;
  font-size: 0.9rem;
  opacity: 0.7;
}

/* Contact Page */
.contact-info-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.contact-info-item .icon {
  font-size: 1.5rem;
  color: var(--secondary-color);
  margin-right: 1rem;
}

.contact-form {
  background: white;
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.form-control {
  border-radius: var(--border-radius);
  padding: 0.75rem 1rem;
  margin-bottom: 1.5rem;
  border: 1px solid #e1e1e1;
  transition: var(--transition);
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--secondary-color);
}

textarea.form-control {
  min-height: 150px;
  resize: vertical;
}

.map-container {
  height: 450px;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow);
}

/* Animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Stats Section */
.stats-section {
  position: relative;
  padding: 5rem 0;
  color: white;
  overflow: hidden;
  background: linear-gradient(rgba(44, 62, 80, 0.9), rgba(44, 62, 80, 0.9)), linear-gradient(to right, #2c3e50, #3498db);
}

.stat-item {
  padding: 2rem;
}

.stat-number {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: var(--secondary-color);
}

.stat-title {
  font-size: 1.2rem;
  margin-bottom: 0;
}

/* Media Queries */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2.5rem;
  }
  
  .section-title h2 {
    font-size: 2rem;
  }
  
  .services-grid, .portfolio-grid {
    grid-template-columns: 1fr;
  }
  
  .services-card, .portfolio-item {
    margin-bottom: 2rem;
  }
  
  .stat-number {
    font-size: 2.5rem;
  }
}